<template>
  <div class="otp-container">
    <div class="otp-otp-container">
      <div class="row-text">
        {{ message.title }}
      </div>
      <div class="row-desc">
        {{ message.message }}
      </div>
      <router-link
        class="w-full flex justify-center"
        v-if="['expired', 'paid','paid_off'].includes(message.status)"
        :to="message.url"
      >
        <span class="otp-btn-login">
          {{
            message.btnText
          }}
        </span>
      </router-link>
      <div v-else class="otp-btn-login" @click="openMidtrans">
        {{
          message.btnText
        }}
      </div>
      <div v-if="['paid_off','paid'].includes(message.status)" class="otp-container5" @click="goPage('my-account')">
        <span class="otp-text1">Go to my order</span>
        <img
          src="/playground_assets/arrow-right-black.svg"
          alt="image"
          class="arrow-right"
        />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import Footer from '../components/footer';
  import Api from '../api';
  import axios from 'axios';
  import Loading from '../components/Loading.vue';

  export default {
    name: 'Payment',
    data: () => ({
      status: null,
      token: null,
      isLoading: false,
      messages: [
        {
          status: 'paid',
          title: 'Payment Successful',
          url: '/',
          btnText: 'Shop Again',
        },
        {
          status: 'paid_off',
          title: 'Payment Successful',
          url: '/',
          btnText: 'Shop Again',
        },
        {
          status: 'expired',
          title: 'Payment Failed',
          message: 'Sorry, your payment was failed and we are not able to process it.',
          url: '/shop/all',
          btnText: 'Shop Again',
        },
        {
          status: 'pending',
          title: 'Payment Not Finished',
          message:
            'You haven’t completed the payment and your order has not been processed yet.',
          url: '/my-account',
          btnText: 'Continue Payment',
        },
        {
          status: 'unsuccess',
          title: 'Payment Not Finished',
          message:
            'You haven’t completed the payment and your order has not been processed yet.',
          url: '/my-account',
          btnText: 'Continue Payment',
        },
      ],
    }),

    async created() {
      await this.getOrderDetail();
    },

    mounted() {
      let snap = document.createElement('script')
      snap.setAttribute('src', 'https://app.sandbox.midtrans.com/snap/snap.js')
      snap.setAttribute('data-client-key', process.env.VUE_APP_MIDTRANS_KEY)
      snap.setAttribute('async', true)
      snap.setAttribute('deffer', true)
      document.head.appendChild(snap)
    },

    computed: {
      message() {
        return this.messages.find((message) => message.status === this.status);
      },
    },

    methods: {
      goPage(page) {
        this.$router.push(page);
      },

      async getOrderDetail() {
        try {
          const { data } = await axios.get(
            `${Api.orderDetailUrl}/${this.$route.query.payment_id}/detail`
          );
          this.status = data.data.status;

          if (data.data.token_repayment) {
            this.token = data.data.token_repayment;
          } else {
            this.token = data.data.token_midtrans;
          }
        } catch (error) {
          throw new Error(error);
        }
      },

      openMidtrans() {
        window.snap.pay(this.token);
      },
    },

    components: {
      Footer,
      Loading,
    },
  };
</script>

<style scoped>
  .arrow-right {
    margin-left: 16px;
  }
  .row-text {
    font-family: Lora;
    font-size: 48px;
    line-height: 64px;
    color: #1f252c;
    font-weight: 700;
    text-align: center;
    margin-bottom: 32px;
  }
  .row-desc {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1f252c;
    margin-bottom: 32px;
    text-align: center;
  }
  .otp-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
  }
  .otp-otp-container {
    flex: 0 0 auto;
    width: 400px;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 180px;
    padding-bottom: 140px;
  }
  .otp-title {
    text-align: center;
    font-family: Montserrat;
    line-height: 2;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .otp-row-box {
    width: 100%;
    display: flex;
    column-gap: 10px;
    align-items: flex-start;
    flex-direction: row;
  }
  .otp-container1 {
    flex: 25%;
    width: 200px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
  }
  .otp-input-otp {
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 2em;
    flex-basis: 25%;
    font-family: Lora;
  }
  .otp-container2 {
    flex: 25%;
    width: 200px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
  }
  .otp-input-otp1 {
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 2em;
    flex-basis: 25%;
    font-family: Lora;
  }
  .otp-container3 {
    flex: 25%;
    width: 200px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
  }
  .otp-input-otp2 {
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 2em;
    flex-basis: 25%;
    font-family: Lora;
  }
  .otp-container4 {
    flex: 25%;
    width: 200px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
  }
  .otp-input-otp3 {
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 2em;
    flex-basis: 25%;
    font-family: Lora;
  }
  .otp-btn-login {
    flex: 0 0 auto;
    width: 100%;
    cursor: pointer;
    height: 44px;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    background-color: #1f252c;
    color: #fff;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.24em;
    text-transform: capitalize;
  }
  .otp-text {
    color: #fff;
    width: 100%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.24em;
    text-transform: capitalize;
    text-align: center;
  }
  .otp-container5 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    margin-top: 24px;
  }
  .otp-text1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.24em;
    text-transform: capitalize;
    color: #1f252c;
  }
  .otp-text2 {
    color: #6b89e8;
    font-family: Montserrat;
    margin-left: var(--dl-space-space-halfunit);
  }
  @media (max-width: 991px) {
    .otp-otp-container {
      width: 100%;
      padding-top: 200px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
</style>
